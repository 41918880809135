import { Modal } from "antd";
import React from "react";
import CreateForm from "./Create/CreateForm";

export default function ModalCreateClient({
  open,
  setOpen,
  companies,
  editingClient,
  setEditingClient,
}) {
  return (
    <div>
      <Modal
        open={open}
        title={
          editingClient
            ? `Зміна користувача ${editingClient.clientName}`
            : `Додавання нового користувача`
        }
        onCancel={() => setOpen(false)}
        footer={null}
        afterClose={() => setEditingClient(null)}
        bodyStyle={{ padding: "30px 0px 0px" }}
      >
        <CreateForm
          companies={companies}
          editingClient={editingClient}
        ></CreateForm>
      </Modal>
    </div>
  );
}
