import { Button, Form, Input, message, Modal, Select } from "antd";
import React, { useState } from "react";
import { addCompany } from "../../api/Clients/clients";

export default function ModalAddCompany({
  open,
  setOpen,
  ftpServers,
  setCompanies,
}) {
  const [selectedServer, setSelectedServer] = useState();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const saveResult = await addCompany(values);
    if (saveResult.status === "error") {
      return message.error(saveResult.message);
    }
    setCompanies((prevCompanies) => [
      ...prevCompanies,
      { companyName: values.companyName, ftpServerName: values.ftpServerName },
    ]);
    setOpen(false);
    message.success(saveResult.message);
  };

  const onFinishFailed = () => {};
  const onChange = (value) => {
    setSelectedServer(value);
  };
  return (
    <div>
      <Modal
        open={open}
        title={"Додавання нової компанії"}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <Form
          className="addCompanyForm"
          name="basic"
          onFinish={onFinish}
          form={form}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Назва Компанії"
            name="companyName"
            rules={[
              {
                required: true,
                message: "Заповніть поле!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Сервер бекапів"
            name="ftpServerId"
            rules={[
              {
                required: true,
                message: "Заповніть поле!",
              },
            ]}
          >
            <Select
              value={selectedServer}
              showSearch
              placeholder="Сервер"
              optionFilterProp="children"
              onChange={onChange}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={ftpServers}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Зберегти
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
