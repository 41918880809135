import { Table, Form, Input, Button, message } from "antd";
import React, { useEffect, useState } from "react";

import { getCompanies, getFtpServers } from "../api/functions";
import ModalAddCompany from "../components/Companies/ModalAddCompany";

const columns = [
  {
    title: "№",
    dataIndex: "id",
    key: "id",
    width: "10%",
    sorter: (a, b) => a.id - b.id,
    defaultSortOrder: "ascend",
  },
  {
    title: "Назва",
    dataIndex: "companyName",
    key: "companyName",
    // width: "35%",
    sorter: (a, b) => a.companyName - b.companyName,
    defaultSortOrder: "descend",
  },
  {
    title: "Сервер Бекапів",
    dataIndex: "ftpServerName",
    key: "ftpServerName",
    render: (_, record) => record.ftpServerName,
    // width: "20%",
    // sorter: (a, b) => a.companyName.localeCompare(b.companyName),
    // defaultSortOrder: "descend",
  },
];

export default function Companies() {
  const [isModalAddCompanyOpen, setIsModalAddCompanyOpen] = useState(false);
  const [companies, setCompanies] = useState();
  const [servers, setServers] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const responseServers = await getFtpServers();
    if (responseServers.status === "error")
      return message.error(responseServers.message);
    const dataServers = responseServers.data;

    setServers(
      dataServers.map((server) => {
        return { value: server.id, label: server.ftpServerName };
      })
    );

    const response = await getCompanies();
    if (response.status === "error") return message.error(response.message);
    const data = response.data;

    console.log(data);
    setCompanies(
      data.map((company, index) => {
        const server = dataServers.find(
          (server) => server.id === company.serverId
        );
        return {
          ...company,
          id: index + 1,
          key: index + 1,
          ftpServerName: server.ftpServerName,
        };
      })
    );
  };

  return (
    <div>
      <ModalAddCompany
        open={isModalAddCompanyOpen}
        setOpen={setIsModalAddCompanyOpen}
        ftpServers={servers}
        setCompanies={setCompanies}
      ></ModalAddCompany>
      <Button
        onClick={() => setIsModalAddCompanyOpen(true)}
        type="primary"
        style={{
          margin: 16,
        }}
      >
        Додати компанію
      </Button>
      <Table columns={columns} dataSource={companies}></Table>
    </div>
  );
}
