import React from "react";
import { Routes, Route } from "react-router-dom";
import Main from "../pages/Main";
import RequireAuth from "../hoc/RequireAuth";
import Nopage from "../pages/Nopage";
import Layout from "../components/Layout";
import Loginpage from "../pages/Loginpage";
import CreateClient from "../pages/CreateClient";
import FtpServers from "../pages/FtpServers";
import Companies from "../pages/Companies";

const AppRouter = () => {
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <Layout></Layout>
            </RequireAuth>
          }
        >
          <Route index element={<Main></Main>}></Route>
          <Route
            path="create-client"
            element={<CreateClient></CreateClient>}
          ></Route>
          <Route path="ftp-servers" element={<FtpServers></FtpServers>}></Route>
          <Route path="companies" element={<Companies></Companies>}></Route>
          <Route path="*" element={<Nopage></Nopage>}></Route>
        </Route>
        <Route path="/login" element={<Loginpage></Loginpage>}></Route>
      </Routes>
    </div>
  );
};

export default AppRouter;
