import React, { useEffect, useState } from "react";
import { Button, Table, Select, Space, Row, message, Checkbox } from "antd";
import {
  convertToGB,
  getCompanies,
  getUsersByCompany,
} from "../../api/Clients/clients";
import {
  getBackUps,
  getClients,
  setBackUpDataToClients,
} from "../../api/Clients/clients";
import { CloudSyncOutlined, UserAddOutlined } from "@ant-design/icons";
import ModalCreateClient from "../Clients/ModalCreateClient";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ModalActions from "./ModalActions";
dayjs.extend(customParseFormat);

export default function ClientsTable() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();
  const [isModalCreateClientOpen, setIsModalCreateClientOpen] = useState(false);
  const [isModalActionsOpen, setIsModalActionsOpen] = useState(false);
  const [editingClient, setEditingClient] = useState();

  useEffect(() => {
    setCompaniesData();
  }, []);

  const setCompaniesData = async () => {
    const response = await getCompanies();
    if (response.status === "error") return message.error(response.message);
    const companies = response.data;
    const companiesOptions = companies.map((company) => {
      return {
        value: company.id,
        label: company.companyName,
        serverId: company.serverId,
      };
    });
    setCompanies(companiesOptions);
  };

  const getAllClients = async () => {
    const clientsArrays = await getClients();
    return clientsArrays.reduce((acc, cur) => acc.concat(cur), []);
  };

  // const setClientsToTable = async () => {
  //   setLoading(true);
  //   const allClients = await getAllClients();
  //   setClients(

  //   );
  //   setLoading(false);
  // };

  const getAllClientsData = async () => {
    setSelectedCompany("Компанія");
    const allClients = await getAllClients();
    setClients();
    await getData([allClients]);
  };

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedUsers(selectedRows);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const getData = async (clientsToGet = clients) => {
    setLoading(true);

    clientsToGet.slice(0, 10);
    const res = await Promise.all(
      clientsToGet.map(async (arr, index) => {
        const selectedCompanyData = companies.find(
          (comp) => comp.value === selectedCompany
        );
        const backups = await getBackUps(
          arr,
          false,
          selectedCompanyData.serverId,
          selectedCompanyData.label
        );
        if (backups) return await setBackUpDataToClients(backups, arr);
      })
    );
    if (res) setClients(res.reduce((acc, cur) => acc.concat(cur), []));

    setLoading(false);
  };

  const onRowAction = async (record) => {
    setEditingClient(record);
    setIsModalActionsOpen(true);
  };

  const columns = [
    {
      title: "№",
      dataIndex: "key",
      key: "key",
      width: "10%",
      sorter: (a, b) => a.key - b.key,
      defaultSortOrder: "descend",
    },
    {
      title: "Клієнт",
      dataIndex: "clientName",
      key: "clientName",
      width: "30%",
      sorter: (a, b) => a.clientName.localeCompare(b.clientName),
      // defaultSortOrder: "descend",
    },
    {
      title: "К-ть",
      dataIndex: "bkpCount",
      key: "bkpCount",
      width: "5%",
      sorter: (a, b) => a.bkpCount - b.bkpCount,
      // defaultSortOrder: "descend",
    },
    {
      title: "Дата останнього",
      dataIndex: "mostRecent",
      key: "mostRecent",
      width: "25%",
      sorter: (a, b) => a.mostRecent - b.mostRecent,
      // defaultSortOrder: "descend",
      render: (_, record) => {
        return record?.backUp?.mostRecent;
      },
    },
    {
      title: "Інфо",
      dataIndex: "error",
      key: "error",
      width: "25%",
      render: (_, record) => {
        return record?.backUp?.error;
      },
      filters: [
        {
          text: "Порожньо",
          value: "empty",
        },
        {
          text: "З помилкою",
          value: "error",
        },
      ],
      onFilter: (value, record) => {
        const error = record?.backUp?.error;
        if (value === "empty") {
          return !error; // Показуємо рядки, де немає помилки (порожньо)
        }
        if (value === "error") {
          return !!error; // Показуємо рядки з помилкою
        }
        return true;
      },
    },
    {
      title: "Розмір",
      dataIndex: "bkpSize",
      key: "bkpSize",
      width: "25%",
      sorter: (a, b) => {
        return a?.backUp?.maxSize - b?.backUp?.maxSize;
      },
      // defaultSortOrder: "descend",
      render: (_, record) => {
        return convertToGB(record?.backUp?.maxSize) + " GB";
      },
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      width: "10%",
      render: (_, record) => {
        const todayBackUp =
          record?.backUp?.mostRecent &&
          dayjs(record?.backUp?.mostRecent, "DD-MM-YYYY HH:mm").isSame(
            dayjs(),
            "day"
          );
        return <Checkbox checked={todayBackUp}></Checkbox>;
      },
      filters: [
        {
          text: "За сьогодні",
          value: "today",
        },
        {
          text: "Раніше",
          value: "before",
        },
        {
          text: "Без бекапу",
          value: "error",
        },
      ],
      onFilter: (value, record) => {
        const mostRecentDate = record?.backUp?.mostRecent
          ? dayjs(record?.backUp?.mostRecent, "DD-MM-YYYY HH:mm")
          : null;

        const isToday = mostRecentDate && mostRecentDate.isSame(dayjs(), "day");
        const hasBackup = !!mostRecentDate;

        if (value === "today") {
          return isToday; // Показуємо записи з бекапом за сьогодні
        }

        if (value === "before") {
          return hasBackup && !isToday; // Показуємо записи з бекапом раніше
        }

        if (value === "error") {
          return !hasBackup; // Показуємо записи без бекапу
        }

        return true;
      },
    },
  ];

  const onChange = async (value) => {
    setSelectedCompany(value);
    const response = await getUsersByCompany(value);
    if (response.status === "error") return message.error(response.message);
    const users = response.data;
    setClients(
      users.map((user, index) => {
        return {
          ...user,
          key: index + 1,
          clientId: user.clientId,
        };
      })
    );
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  const getBackUpsByCompany = async (company) => {
    if (!company && !selectedUsers.length)
      return message.error("Виберіть компанію або користувачів");
    if (selectedUsers.length > 0) {
      getData([selectedUsers]);
      return;
    }
    getData([clients]);
  };

  return (
    <>
      <Row style={{ padding: "10px" }}>
        <Space direction="horizontal">
          {/* <Button onClick={getAllClientsData}>
            <Loading3QuartersOutlined /> Оновити всі дані
          </Button> */}
          <Select
            style={{ width: "40vh" }}
            value={selectedCompany}
            showSearch
            placeholder="Компанія"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={companies}
          />

          <Button
            onClick={async () => await getBackUpsByCompany(selectedCompany)}
          >
            <CloudSyncOutlined />
          </Button>
          <Button onClick={() => setIsModalCreateClientOpen(true)}>
            <UserAddOutlined />
            Додати клієнта
          </Button>
        </Space>
      </Row>
      <br></br>
      <ModalCreateClient
        open={isModalCreateClientOpen}
        setOpen={setIsModalCreateClientOpen}
        companies={companies}
        editingClient={editingClient}
        setEditingClient={setEditingClient}
      ></ModalCreateClient>
      <Table
        loading={loading}
        pagination={{
          position: ["bottomCenter"],
          hideOnSinglePage: true,
          pageSize: 50,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              onRowAction(record);
            },
          };
        }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={clients}
        size={"middle"}
        tableLayout="fixed"
      />
      {isModalActionsOpen && (
        <ModalActions
          isModalActionsOpen={isModalActionsOpen}
          setIsModalActionsOpen={setIsModalActionsOpen}
          setIsModalCreateClientOpen={setIsModalCreateClientOpen}
          editingClient={editingClient}
          setClients={setClients}
        ></ModalActions>
      )}
    </>
  );
}
