import React, { useEffect } from "react";
import { Button, Col, Form, Input, Row, Select, message } from "antd";
import { createClient } from "../../../api/Clients/clients";

export default function CreateForm({ companies, editingClient = null }) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (!editingClient) return form.resetFields();
    const userCompany = companies.find(
      (comp) => comp.value === editingClient.company_id
    );

    form.setFieldsValue({
      pathToFolder: editingClient.path,
      companyId: editingClient.company_id,
      bkpQty: editingClient.bkpCount,
      clientName: editingClient.clientName,
      serverId: userCompany.serverId,
    });
  }, [editingClient]);

  const onFinish = async (values) => {
    const saveResult = await createClient(values);
    if (saveResult.status === "error") {
      return message.error(saveResult.message);
    }
    message.success(saveResult.message);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Row>
        <Col>
          <Form
            name={"upsertForm"}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Компанія"
              name="companyId"
              rules={[
                {
                  required: true,
                  message: "Please input companyName!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Компанія"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={companies}
              />
            </Form.Item>
            <Form.Item
              label="Назва клієнта"
              name="clientName"
              rules={[
                {
                  required: true,
                  message: "Please input username!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="К-ть бекапів"
              name="bkpQty"
              rules={[
                {
                  required: true,
                  message: "Перевірте правильність введення!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Шлях до папки"
              name="pathToFolder"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Зберегти
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
}
