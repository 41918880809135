import { Table, Form, Input, Button, message } from "antd";
import React, { useEffect, useState } from "react";
import ModalAddServer from "../components/Servers/ModalAddServer";
import { getFtpServers } from "../api/functions";

const columns = [
  {
    title: "№",
    dataIndex: "id",
    key: "id",
    width: "10%",
    sorter: (a, b) => a.id - b.id,
    defaultSortOrder: "ascend",
  },
  {
    title: "Назва",
    dataIndex: "ftpServerName",
    key: "ftpServerName",
    width: "35%",
    // sorter: (a, b) => a.bkpCount - b.bkpCount,
    // defaultSortOrder: "descend",
  },
  {
    title: "Адреса",
    dataIndex: "ftpAddress",
    key: "ftpAddress",
    width: "20%",
    // sorter: (a, b) => a.companyName.localeCompare(b.companyName),
    // defaultSortOrder: "descend",
  },
  {
    title: "Порт",
    dataIndex: "ftpPort",
    key: "ftpPort",
    width: "10%",
    // sorter: (a, b) => a.clientName.localeCompare(b.clientName),
    // defaultSortOrder: "descend",
  },
  {
    title: "Користувач",
    dataIndex: "ftpUser",
    key: "ftpUser",
    // width: "15%",
    // sorter: (a, b) => a.bkpCount - b.bkpCount,
    // defaultSortOrder: "descend",
  },
  {
    title: "Пароль",
    dataIndex: "ftpPassword",
    key: "ftpPassword",
    // width: "15%",
    // sorter: (a, b) => a.bkpCount - b.bkpCount,
    // defaultSortOrder: "descend",
  },
];

export default function FtpServers() {
  const [isModalAddServerOpen, setIsModalAddServerOpen] = useState(false);
  const [servers, setServers] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const response = await getFtpServers();
    if (response.status === "error") return message.error(response.message);
    const data = response.data;

    setServers(
      data.map((server, index) => {
        return { ...server, id: index + 1, key: index + 1 };
      })
    );
  };

  return (
    <div>
      <ModalAddServer
        open={isModalAddServerOpen}
        setOpen={setIsModalAddServerOpen}
      ></ModalAddServer>
      <Button
        onClick={() => setIsModalAddServerOpen(true)}
        type="primary"
        style={{
          margin: 16,
        }}
      >
        Додати сервер
      </Button>
      <Table columns={columns} dataSource={servers}></Table>
    </div>
  );
}
