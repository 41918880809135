import { getServer } from "../utils/Environment";

const server = getServer();

export async function getFtpServers() {
  try {
    const result = await fetch(server + "/server/server.php", {
      method: "POST",
      body: JSON.stringify({ action: "getFtpServers" }),
    });
    return await result.json();
  } catch (error) {
    console.log(error);
    throw error;
    // return false;
  }
}

export async function getCompanies() {
  try {
    const result = await fetch(server + "/server/server.php", {
      method: "POST",
      body: JSON.stringify({ action: "getCompanies" }),
    });
    return await result.json();
  } catch (error) {
    console.log(error);
    throw error;
    // return false;
  }
}
