import React, { useState } from "react";
import { Menu, message } from "antd";
import {
  UserAddOutlined,
  UserOutlined,
  UserDeleteOutlined,
  HomeOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  getBackUps,
  getClients,
  getCompanies,
  getUsersByCompany,
} from "../../api/Clients/clients";

export default function TopMenu(props) {
  const navigate = useNavigate();

  const items = [
    {
      label: "Головна",
      key: "main",
      icon: <HomeOutlined />,
      onClick: () => navigate("/"),
    },
    {
      label: "Компанії",
      key: "companies",
      icon: <UsergroupAddOutlined />,
      onClick: () => navigate("companies"),
    },
    {
      label: "Сервера",
      key: "servers",
      icon: <UserOutlined />,
      onClick: () => navigate("ftp-servers"),
    },
    {
      label: "Розсилка",
      key: "telegram",
      icon: <HomeOutlined />,
      onClick: () => checkBackupsAndTelegram(),
    },
  ];

  const [current, setCurrent] = useState("mail");
  const onClick = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
  };

  async function checkBackupsAndTelegram() {
    const response = await getCompanies();
    if (response.status === "error") return message.error(response.message);
    const companies = response.data;
    companies.map(async (company) => {
      const response = await getUsersByCompany(company.id);
      if (response.status === "error") return message.error(response.message);
      const clientsOfCompany = response.data;
      const backups = await getBackUps(
        clientsOfCompany,
        true,
        company.serverId,
        company.companyName
      );
    });
  }

  return (
    <>
      <Menu
        onClick={onClick}
        selectedKeys={[current]}
        mode="horizontal"
        items={items}
      />
    </>
  );
}
