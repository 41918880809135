import { Modal, List, message } from "antd";

export const ModalActions = (props) => {
  const handleCancel = () => {
    props.setIsModalActionsOpen(false);
  };

  const handleMenuClick = async (record) => {
    switch (record.key) {
      case "edit":
        props.setIsModalActionsOpen(false);
        props.setIsModalCreateClientOpen(true);
        break;
      case "delete":
        message.error("Так собі ідея");
        break;
      default:
        break;
    }
  };

  const data = [
    {
      title: "Змінити",
      key: "edit",
    },
    {
      title: "Видалити",
      key: "delete",
    },
  ];

  return (
    <>
      <Modal
        title={"Користувач " + props.editingClient?.clientName}
        style={{ textAlign: "center" }}
        open={props.isModalActionsOpen}
        footer={null}
        onCancel={handleCancel}
      >
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item onClick={(e) => handleMenuClick(item)}>
              {item.title}
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
};
export default ModalActions;
