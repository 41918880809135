import { Form, Input, Modal } from "antd";
import React from "react";

export default function ModalAddServer({ open, setOpen }) {
  const onFinish = () => {};

  const onFinishFailed = () => {};
  return (
    <div>
      <Modal
        open={open}
        title={"Додавання нового сервера"}
        onCancel={() => setOpen(false)}
      >
        <Form
          className="addFtpServerForm"
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Назва Сервера"
            name="ftpServerName"
            rules={[
              {
                required: true,
                message: "Заповніть поле!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Адреса Сервера"
            name="ftpAddress"
            rules={[
              {
                required: true,
                message: "Заповніть поле!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Порт Сервера"
            name="ftpPort"
            rules={[
              {
                required: true,
                message: "Заповніть поле!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Користувач"
            name="ftpUser"
            rules={[
              {
                required: true,
                message: "Заповніть поле!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Пароль"
            name="ftpPassword"
            rules={[
              {
                required: true,
                message: "Заповніть поле!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Телеграм"
            name="TelegramId"
            rules={[
              {
                required: true,
                message: "Заповніть поле!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
